<template>
  <div class="overall">
    <div class="content">
      <!-- 检索条件 -->
      <el-form ref="form" :model="form"> 
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form label-width="60px">
            <el-form-item label="门店">
                <el-select v-model="form.siteId" placeholder="请选择门店" style="width: 100%" :clearable="true">
                  <el-option
                    v-for="item in siteIdList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6">
          <el-form label-width="60px">
            <el-form-item label="日期">
              <el-date-picker
                v-model.trim="form.createAt"
                type="month"
                value-format="yyyy-MM"
                style="width: 100%;"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6">
            <el-button type="primary" @click="reset()">重置</el-button>
            <el-button type="primary" @click="handleExport()">导出</el-button>
        </el-col>
      </el-row> 
    </el-form>
    </div>
  </div>
</template>
<script>
import {siteInfoList, } from '../../../api/restaurant';
export default {
  data(){
    return{
      siteIdList: [],
      form:{
        siteId : "",
        createAt : "",
      },
    }
  },
  // 1.页面初始化方法
  mounted(){
    this.siteInfoList();
  },
  methods: {
    async siteInfoList() {
      try {
        let data = await siteInfoList();
        const hubArr = [];
        data.forEach(item => {
          hubArr.push({
            value: item.id,
            label: item.name
          })
        })
        this.siteIdList = hubArr;
      } catch (error) {
        console.log("error:", error)
      }
      
    },

    // 重置
    reset() {
      this.form.siteId = "";
      this.form.createAt="";
    },
    // 导出数据
    handleExport() {
            if(this.form.siteId === ""){
              this.$message({
                          showClose: true,
                          type: "warning",
                          message: "请先选择门店!"
                    });
                    return;
            }
            if(this.form.createAt === ""){
              this.$message({
                          showClose: true,
                          type: "warning",
                          message: "请先选择时间!"
                    });
                    return;
            }
           this.$confirm("确认导出数据?", {
               confirmButtonText: "确定",
               cancelButtonText: "取消",
               type: "warning"
           }).then(() => {
               window.open(`/deliveryPuch/createExcel?siteId=${this.form.siteId}&createAt=${this.form.createAt}`);
           }).then(() => {
               this.$message({
                    showClose: true,
                    type: "success",
                    message: "导出成功!"
               });
            })
       },
  }
}
</script>

<style lang="scss" scoped>
.overall {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}
</style>